import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import CancelSubscriptionForm from 'elements/CancelSubscriptionForm';

class CancelSubscription extends PureComponent {

  constructor(props, context) {
    super(props, context);
		this.state = {
      showReasonForm: false,
		};
  }

  handleContinue = event => {
    event.preventDefault();
    this.setState({ showReasonForm: true });
  }

  renderBenefitsScreen() {
    return (
      <div className="benefits-screen">
        <div className="row">
          <div className="col-md-12">
            <span><strong>What happens when I downgrade my FloProtect?</strong></span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span><span className="warning">Warning</span> - You will no longer have access to or be covered by the following FloProtect Plan features:</span>
            <ul className="dashed">
                <li>We-Pay $5,000 Deductible Guarantee</li>
                <li>5-year Extended Warranty</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 footer-text">
            <span>Are you sure you want to return to covering your own water damage deductible, a standard warranty, and no assistance within your Flo by Moen app?</span>
          </div>
        </div>
        <div className="center-block">
          <button className="btn btn-lg btn-subscribe btn-cancel-subscription btn-back" onClick={this.props.handleClose}>No, keep me protected</button>
          <button className="btn btn-lg btn-subscribe btn-cancel-subscription btn-continue" onClick={this.handleContinue}>Yes, I want to deactivate</button>
        </div>
      </div>
    );
  }

  renderCancelSubscriptionForm() {
    return (
      <div className="reason-form">
        <div className="row">
          <div className="col-md-12">
            <span className="reason-title">We are sorry you don't feel the FloProtect Plan at only $5/mo is not worth the value. Can you please provide some additional details on why you are deactivating your plan?</span>
          </div>
        </div>

        <CancelSubscriptionForm 
          errorMessage  = { this.props.errorMessage }
          onSubmit      = { this.props.handleSubmit }
          onClose       = { this.props.handleClose }
        />
      </div>
    );
  }

  render() {
    return (
      <div className={ 'checkout ' + this.props.stateClass }>
        <div className="credit-card-payment cancel-subscription">

          <div className="row">
            <div className="col-12">
              <h2>Deactivate my FloProtect Plan</h2>
            </div>
          </div>

          { !this.state.showReasonForm && this.renderBenefitsScreen() }
          { this.state.showReasonForm && this.renderCancelSubscriptionForm() }
        </div>

        <div className="success">
          <div className="icon">
            <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#000" fill="none"></circle>
              <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#000" fill="none"></path>
            </svg>
          </div>
          <h3 className="title">FloProtect Successfully Downgraded</h3>
          <p className="message lg-text"><span>Thank you for your feedback!</span></p>
          { this.props.cancelAtPeriodEnd && this.props.status == 'active' &&
            <p className="note lg-text"><span><strong>Your subscription will be deactivated at the end of the current billing period.</strong></span></p>
          }
          <button className="btn btn-lg btn-subscribe btn-subscribe-pro" onClick={this.props.handleClose}>Return to Manage Your Plan</button>
        </div>
      </div>
    );
  }
}

CancelSubscription.propTypes = {
  stateClass: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  cancelAtPeriodEnd: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default CancelSubscription;
